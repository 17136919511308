import React from "react";

// Libraries
import { animated } from "react-spring";

function CardRange({ question, index, style, image }) {
    return (
        <animated.div style={style} className="card">
            <div
                className="card-question flex flex-center shadow-xl relative img-background"
                style={{
                    backgroundImage: `url('${process.env.PUBLIC_URL}/icons/${
                        image || "D1"
                    }.png')`,
                }}
            >
                <div className="absolute top left pt pl fw-b c-sec">
                    {index}
                </div>
                <h3 className="fs-l">{question}</h3>
            </div>
        </animated.div>
    );
}

export default CardRange;
