export const streets = [
    { value: "Anna Bijnsring", label: "Anna Bijnsring" },
    { value: "Anna Blamanplantsoen", label: "Anna Blamanplantsoen" },
    { value: "Balustrade", label: "Balustrade" },
    { value: "Belle van Zuylenbrink", label: "Belle van Zuylenbrink" },
    { value: "Bloemenweide", label: "Bloemenweide" },
    { value: "Carry van Bruggenstraat", label: "Carry van Bruggenstraat" },
    { value: "De Binnentuin", label: "De Binnentuin" },
    { value: "De Bloementuin", label: "De Bloementuin" },
    { value: "De Boomgaard", label: "De Boomgaard" },
    { value: "De Bron", label: "De Bron" },
    { value: "De Moseik", label: "De Moseik" },
    { value: "De Ontvangst", label: "De Ontvangst" },
    { value: "De Pergola", label: "De Pergola" },
    { value: "De Rotstuin", label: "De Rotstuin" },
    { value: "De Steeneik", label: "De Steeneik" },
    { value: "De Stille Tuin", label: "De Stille Tuin" },
    { value: "De Terrassen", label: "De Terrassen" },
    { value: "De Tol", label: "De Tol" },
    { value: "De Waterkamer", label: "De Waterkamer" },
    { value: "De Wintereik", label: "De Wintereik" },
    { value: "De Zomereik", label: "De Zomereik" },
    { value: "Dennenkamp", label: "Dennenkamp" },
    { value: "Deventerstraat", label: "Deventerstraat" },
    { value: "Diet Kramerstraat", label: "Diet Kramerstraat" },
    { value: "Etty Hillesumplantsoen", label: "Etty Hillesumplantsoen" },
    { value: "Groene Weide", label: "Groene Weide" },
    { value: "Henriëtte van Eyklaan", label: "Henriëtte van Eyklaan" },
    { value: "Het Firmament", label: "Het Firmament" },
    { value: "Het Verlaat", label: "Het Verlaat" },
    { value: "Ina Boudier-Bakkersingel", label: "Ina Boudier-Bakkersingel" },
    {
        value: "Jacqueline van der Waalssingel",
        label: "Jacqueline van der Waalssingel",
    },
    { value: "Josine Reulingstraat", label: "Josine Reulingstraat" },
    { value: "Kruidenpad", label: "Kruidenpad" },
    { value: "Linie", label: "Linie" },
    { value: "Lupineweg", label: "Lupineweg" },
    { value: "Marie Koenenstraat", label: "Marie Koenenstraat" },
    { value: "Osseveldweg", label: "Osseveldweg" },
    { value: "Top Naeffstraat", label: "Top Naeffstraat" },
    { value: "Veenhuizerweg", label: "Veenhuizerweg" },
    { value: "Vosweide", label: "Vosweide" },
    { value: "Waterlei", label: "Waterlei" },
    { value: "Zonnewende", label: "Zonnewende" },
    { value: "Zutphensestraat", label: "Zutphensestraat" },
];

export const genders = [
    { value: "Man", label: "Man" },
    { value: "Vrouw", label: "Vrouw" },
    { value: "Anders", label: "Anders" },
];

export const arrangements = [
    { value: "Koop", label: "Koop" },
    { value: "Huur", label: "Huur" },
];

export const types = [
    {
        value: "Eengezinswoning rij",
        label: "Eengezinswoning rij",
    },
    {
        value: "Twee onder één kap",
        label: "Twee onder één kap",
    },
    {
        value: "Vrijstaande woning",
        label: "Vrijstaande woning",
    },
    {
        value: "Appartement/flat",
        label: "Appartement/flat",
    },
    {
        value: "Ouderen/senioren woning",
        label: "Ouderen/senioren woning",
    },
];

export const owners = [
    {
        value: "Ik heb een koophuis",
        label: "Ik heb een koophuis",
    },
    {
        value: "ik huur van een particulier",
        label: "ik huur van een particulier",
    },
    {
        value: "ik huur van de woningbouwcoöperatie",
        label: "ik huur van de woningbouwcoöperatie",
    },
];

export const ages = [
    { value: "16 of jonger", label: "16 of jonger" },
    { value: "17-20 jaar", label: "17-20 jaar" },
    { value: "21-29 jaar", label: "21-29 jaar" },
    { value: "30-39 jaar", label: "30-39 jaar" },
    { value: "40-49 jaar", label: "40-49 jaar" },
    { value: "50-59 jaar", label: "50-59 jaar" },
    { value: "60-69 jaar", label: "60-69 jaar" },
    { value: "70 of ouder", label: "70 of ouder" },
];

export const samenstellingen = [
    { value: "Alleen", label: "Alleen" },
    { value: "Samen met partner", label: "Samen met partner" },
    {
        value: "Samen met partner en 1 kind",
        label: "Samen met partner en 1 kind",
    },
    {
        value: "Samen met partner en meerdere kinderen",
        label: "Samen met partner en meerdere kinderen",
    },
    { value: "Alleenstaand met 1 kind", label: "Alleenstaand met 1 kind" },
    {
        value: "Alleenstaand met meerdere kinderen",
        label: "Alleenstaand met meerdere kinderen",
    },
];
