import React, { useState } from "react";

// Libraries
import { connect } from "react-redux";

// Core
import { signInAnonymously } from "../../core/session";

// Components
import Container from "../components/container";
import Button from "../components/buttons";
import Image from "../components/images/image";
import { IconRight, IconSpin } from "../components/icons";

export default connect(null, { signInAnonymously })(({ signInAnonymously }) => {
    const [loading, toggleLoading] = useState(false);

    const submitSignIn = async () => {
        toggleLoading(true);

        await signInAnonymously();

        toggleLoading(false);
    };

    return (
        <Container>
            <div className="flex flex-align flex-space flex-center">
                <Image
                    height="230px"
                    src={`${process.env.PUBLIC_URL}/logos/WoudhuisLogo.png`}
                />
            </div>
            <div className="flex row flex-align flex-space">
                <div className="col-3">
                    <Image
                        width="100%"
                        src={`${process.env.PUBLIC_URL}/logos/ApeldoornLogo.png`}
                    />
                </div>
                <div className="col-3">
                    <Image
                        width="100%"
                        src={`${process.env.PUBLIC_URL}/logos/EurusLogo.png`}
                    />
                </div>
                <div className="col-3">
                    <Image
                        width="100%"
                        src={`${process.env.PUBLIC_URL}/logos/StimenzLogo.png`}
                    />
                </div>
                <div className="col-3">
                    <Image
                        height="30px"
                        src={`${process.env.PUBLIC_URL}/logos/WijkraadLogo.png`}
                    />
                </div>
            </div>
            <p className="relative mt-m">
                In beeld verschijnen stellingen onderverdeeld in 5 thema’s:
                Sociaal, Mobiliteit, Veiligheid, Duurzaamheid en Openbare
                ruimte. Door te schuiven met het balkje onder de stelling kunt u
                aangeven in hoeverre u het ‘eens’ of ‘oneens’ bent met de
                stelling. Vervolgens klikt u op ‘volgende’ en verschijnt de
                volgende stelling in beeld. Succes met het invullen!
            </p>
            <Button
                onClick={submitSignIn}
                disabled={loading}
                className="full mt-m"
                text={loading ? "Loading..." : "Starten"}
            >
                {loading ? (
                    <IconSpin className="ml icon icon-sm spin" />
                ) : (
                    <IconRight className="ml icon icon-sm" />
                )}
            </Button>
        </Container>
    );
});
