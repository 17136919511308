import React from "react";

// Components
import CardRange from "./card-range";
import CardCompetition from "./card-competition";
import CardIntro from "./card-intro";
import CardOpen from "./card-open";

function Card({ style, item, onChange }) {
    if (item.slider) {
        return <CardRange {...item} style={style} />;
    }

    if (item.open) {
        return <CardOpen {...item} style={style} />;
    }

    if (item.competition) {
        return (
            <CardCompetition {...item} style={style} onComplete={onChange} />
        );
    }

    if (item.intro) {
        return <CardIntro {...item} style={style} />;
    }

    return null;
}

export default Card;
