import React, { useState, useRef, Fragment } from "react";

// Libraries
import { useTransition } from "react-spring";

// Components
import Card from "./cards";
import Selectors from "./selectors";

function List({ list, completed = [], setAnswer, setCompleted }) {
    const done = useRef(new Set(completed));
    const direction = useRef(1);

    const filterQuestions = () =>
        list.filter((item) => !done.current.has(item.id))[0];

    const [currentItem, setCurrentItem] = useState(filterQuestions());

    const transitions = useTransition(currentItem, (item) => item.id, {
        from: {
            opacity: 0,
            transform: "scale(0.8) translate3d(0px,20%,0) rotate(0deg)",
        },
        enter: {
            opacity: 1,
            transform: "scale(1) translate3d(0px,0%,0) rotate(0deg)",
        },
        leave: {
            opacity: 0,
            transform: `scale(1) translate3d(${
                (200 + window.innerWidth) * direction.current
            }px,0%,0) rotate(45deg)`,
        },
    });

    const onChange = (payload = null) => {
        if (currentItem.slider) {
            direction.current = payload < 5 ? -1 : 1;
            setAnswer({
                id: currentItem.id,
                slider: true,
                result: payload,
            });
        }

        if (currentItem.competition) {
            setAnswer({
                id: currentItem.id,
                competition: true,
                result: payload,
            });
        }

        if (currentItem.intro) {
            setAnswer({
                id: currentItem.id,
                intro: true,
            });
        }

        if (currentItem.open) {
            setAnswer({
                id: currentItem.id,
                open: true,
                result: payload,
            });
        }

        done.current.add(currentItem.id);

        if (done.current.size === list.length) {
            setTimeout(() => setCompleted(), 400);
            return;
        }

        return setCurrentItem(filterQuestions());
    };

    return (
        <Fragment>
            <div className="relative card-height">
                {transitions.map(({ key, item, props }) => (
                    <Card
                        style={props}
                        key={key}
                        item={item}
                        onChange={onChange}
                    />
                ))}
            </div>
            <div className="relative z-index">
                <Selectors onChange={onChange} item={currentItem} />
            </div>
        </Fragment>
    );
}

export default List;

//
// import React, { useState, Fragment } from "react";

// // Libraries
// import { useSprings, animated, config } from "react-spring";
// import { useDrag } from "react-use-gesture";

// // Components
// import BackgroundImage from "../../components/images/background";
// import Selectors from "./selectors";

// const types = {
//     IMAGE: "IMAGE",
//     SWIPE: "SWIPE",
// };

// const setTo = (index, currentIndex) => {
//     if (index !== currentIndex && index !== currentIndex + 1) {
//         return;
//     }
//     return {
//         x: 0,
//         y: setY(index, currentIndex),
//         scale: setScale(index, currentIndex),
//         config: config.wobbly,
//     };
// };
// const setFrom = (i) => ({
//     x: 0,
//     y: 0,
//     scale: 1,
//     rotate: 0,
// });

// const setY = (index, currentIndex) => {
//     if (index === currentIndex) {
//         return -40;
//     }

//     if (index === currentIndex + 1) {
//         return -20;
//     }

//     return 0;
// };

// const setScale = (index, currentIndex) => {
//     if (index === currentIndex) {
//         return 1.1;
//     }

//     if (index === currentIndex + 1) {
//         return 1.05;
//     }

//     return 1;
// };

// export default ({
//     list,
//     currentIndex,

//     setIndex,
//     setAnswer,
//     setCompleted,
// }) => {
//     const [gone] = useState(() => new Set()); // The set flags all the cards that are flicked out
//     const [props, set] = useSprings(list.length, (i) => ({
//         ...setTo(i, currentIndex),
//         from: setFrom(i),
//     })); // Create a bunch of springs using the helpers above

//     const bind = useDrag(
//         ({
//             args: [index],
//             down,
//             movement: [mx, my],
//             distance,
//             direction: [xDir],
//             cancel,
//         }) => {
//             const dir = xDir < 0 ? -1 : 1; // Direction should either point left or right

//             if (list[index].type === types.SWIPE) return;

//             if (down && distance > window.innerWidth / 3) {
//                 setIndex(index + 1);
//                 setAnswer({
//                     id: list[index].id,
//                     answer: dir === 1 ? true : false,
//                 });
//                 gone.add(index);
//                 cancel();
//             }

//             const leaving = gone.has(index);

//             set((i) => {
//                 if (leaving && i === currentIndex) {
//                     return {
//                         y: -40,
//                         scale: 1.1,
//                         config: config.wobbly,
//                     };
//                 }

//                 if (leaving && i === currentIndex + 1) {
//                     return {
//                         y: -20,
//                         scale: 1.05,
//                         config: config.wobbly,
//                     };
//                 }

//                 if (i !== index) return;

//                 const x = leaving
//                     ? (200 + window.innerWidth) * dir
//                     : down
//                     ? mx
//                     : 0;

//                 const y = leaving ? my : down ? my : -40;

//                 return {
//                     x,
//                     y,
//                     delay: undefined,
//                     config: {
//                         friction: 50,
//                         tension: down ? 800 : leaving ? 200 : 500,
//                     },
//                 };
//             });

//             if (!down && gone.size === list.length) {
//                 setTimeout(() => setCompleted(), 400);
//             }
//         }
//     );

//     const onChange = (payload) => {
//         setAnswer({
//             id: list[currentIndex].id,
//             answer: payload,
//         });

//         const direction =
//             list[currentIndex].type === types.IMAGE
//                 ? !payload
//                     ? -1
//                     : 1
//                 : payload < 2
//                 ? -1
//                 : 1;

//         setIndex(currentIndex + 1);

//         gone.add(currentIndex);

//         set((i) => {
//             if (i === currentIndex + 1) {
//                 return {
//                     y: -40,
//                     scale: 1.1,
//                     config: config.wobbly,
//                 };
//             }

//             if (i === currentIndex + 2) {
//                 return {
//                     y: -20,
//                     scale: 1.05,
//                     config: config.wobbly,
//                 };
//             }

//             if (i !== currentIndex) return;

//             const x = (200 + window.innerWidth) * direction;

//             return {
//                 x,
//                 rotate: payload ? 45 : -45,

//                 config: {
//                     friction: 50,
//                     tension: 200,
//                 },
//             };
//         });
//         if (gone.size === list.length) {
//             setTimeout(() => setCompleted(), 400);
//         }
//     };

//     return (
//         <Fragment>
//             <p className="center pb-xl">
//                 Passen onderstaande foto's, woorden en kleuren bij het beeld,
//                 gevoel en stijl dat Hart van Zuid bij u oproept?
//             </p>
//             <div className="relative card-height">
//                 {props
//                     .map(({ x, y, scale, rotate }, index) => {
//                         return (
//                             <animated.div
//                                 {...bind(index)}
//                                 key={index}
//                                 style={{ x, y, scale, rotate }}
//                                 className="card flex flex-align center"
//                             >
//                                 <BackgroundImage
//                                     inview={
//                                         index === currentIndex ||
//                                         index === currentIndex + 1 ||
//                                         index === currentIndex + 2 ||
//                                         index < currentIndex
//                                     }
//                                     src={`${process.env.PUBLIC_URL}/images/${list[index].id}.png`}
//                                 />
//                             </animated.div>
//                         );
//                     })
//                     .reverse()}
//             </div>

//             <Selectors
//                 onChange={onChange}
//                 currentItem={list[currentIndex] || null}
//             />
//         </Fragment>
//     );
// };
