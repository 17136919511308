import React from "react";

// Libraries
import { animated } from "react-spring";

function CardRange({ text, description, style, image }) {
    return (
        <animated.div style={style} className="card">
            <div
                className="card-question flex flex-center shadow-xl relative img-background"
                style={{
                    backgroundImage: `url('${process.env.PUBLIC_URL}/icons/${
                        image || "D1"
                    }.png')`,
                }}
            >
                {!description && <h3 className="fs-l">{text}</h3>}
                {description && <p>{description}</p>}
            </div>
        </animated.div>
    );
}

export default CardRange;
