import React from "react";

// Libraries
import { animated } from "react-spring";

function CardOpen({ index, style }) {
    return (
        <animated.div style={style} className="card">
            <div className="card-question flex flex-center shadow-xl relative img-background">
                <div className="absolute top left pt pl fw-b c-sec">
                    {index}
                </div>
                <h3 className="fs-l">
                    Heeft u nog aanvullingen / opmerkingen over dit thema?
                </h3>
            </div>
        </animated.div>
    );
}

export default CardOpen;
